import React from 'react';
import Sidebar from './sidebar/Sidebar';
import ChatWindow from './body/ChatWindow';

const DefaultLayout = (apiConfig) => {
    return (
        <>
            <Sidebar apiConfig={apiConfig.apiConfig} setApiConfig={apiConfig.setApiConfig}/>
            <ChatWindow apiConfig={apiConfig.apiConfig} setApiConfig={apiConfig.setApiConfig}/>
        </>
    )
}
export default DefaultLayout