import React, { useEffect, useState, useRef } from 'react';
import { FaUserCircle, FaSignOutAlt } from 'react-icons/fa';
import './Account.css';
import '../Modal.css';
import { keycloak, removeRoleFromUser } from '../../keycloak';

const Account = ({ apiConfig, setApiConfig }) => {
    const [userImage, setUserImage] = useState();
    const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);
    const [isWithdrawalModalOpen, setIsWithdrawalModalOpen] = useState(false);
    const userImageRef = useRef(null);

    const handleLogoutClick = () => {
        // Apply animation class
        if (userImageRef.current) {
            userImageRef.current.classList.add('animate');
        }

        // Create and append the overlay element
        const overlay = document.createElement('div');
        overlay.className = 'overlay crumple';
        document.body.appendChild(overlay);

        // Wait for the animation to complete before logging out
        setTimeout(() => {
            keycloak.logout();
        }, 1000); // Match this timeout with the animation duration

        // Remove the overlay after animation (optional, for cleanup)
        setTimeout(() => {
            document.body.removeChild(overlay);
        }, 1500); // Duration after which the overlay will be removed
    };

    useEffect(() => {
        const fetchUserImage = async () => {
            const response = await fetch(`https://sapeon-llm-api.artiference.sapeon.net/api/v1/user/profile`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + keycloak.token,
                },
            });
            const data = await response.json();
            if (!response.ok) {
                setUserImage('');
            } else {
                setUserImage(atob(data.image).split(';base64,').pop());
            }
        };
        fetchUserImage();
    }, [apiConfig.llmAPIServerURL]);

    const handleAccountClick = () => {
        setIsAccountModalOpen(true);
    };

    const handleAccountModalConfirm = () => {
        setIsAccountModalOpen(false);
    };

    const handleWithdrawalClick = () => {
        setIsWithdrawalModalOpen(true);
    };

    const handleWithdrawalModalYes = async () => {
        const token = keycloak.token;
        const closeChatRequest = fetch(`${apiConfig.llmBackendURL}/v1/chat/close`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        });
        const deleteLLMRequest = fetch(`https://sapeon-llm-api.artiference.sapeon.net'/api/v1/llm/${apiConfig.llm_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({
                model: apiConfig.model,
            }),
        });
        const keycloakRoles = keycloak.tokenParsed.realm_access.roles.filter(role => role.includes('sapeon-llm'));
        for (const role of keycloakRoles) {
            await removeRoleFromUser(keycloak.tokenParsed.sub, role);
        }
        keycloak.logout();
        setIsWithdrawalModalOpen(false);
        try {
            const closeChatResponse = await closeChatRequest;
            if (!closeChatResponse.ok) {
                throw new Error('Close chat request failed');
            }
    
            const deleteLLMResponse = await deleteLLMRequest;
            if (!deleteLLMResponse.ok) {
                throw new Error('Delete LLM request failed');
            }
    
            const deleteLLMData = await deleteLLMResponse.json();
            setApiConfig({ ...apiConfig, llm_id: '', model: apiConfig.model, page_id: '', page_name: '', llmBackendURL: '', llmAPIServerURL: apiConfig.llmAPIServerURL });
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };
    

    const handleWithdrawalModalNo = () => {
        setIsWithdrawalModalOpen(false);
    };
    
    return (
        <div className='account-container'>
            <div className='profile'>
                {userImage ? (
                    <img
                        src={`data:image/png;base64,${userImage}`}
                        className='user-image'
                        ref={userImageRef}
                    />
                ) : (
                    <FaUserCircle className='user-image' ref={userImageRef} />
                )}
                <FaSignOutAlt className='logout-icon' onClick={handleLogoutClick} />
                <div className='info'>
                    <div className='name'>{keycloak.idTokenParsed.preferred_username}</div>
                    <div className='email'>{keycloak.idTokenParsed.email}</div>
                </div>
            </div>
            <div className='level'>
                {apiConfig.membership === 'prime' || 'admin' ? (
                    ""
                    ) : (
                        <button className='account-button' onClick={handleAccountClick}>
                            Upgrade to Plus
                        </button>
                )}
                <button className='withdrawal-button' onClick={handleWithdrawalClick}>
                    Membership withdrawal
                </button>
            </div>
            {isAccountModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <p>
                            Would you like to upgrade your SAPEON-LLM membership?<br />
                            Please contact us!
                        </p>
                        <button onClick={handleAccountModalConfirm}>Confirm</button>
                    </div>
                </div>
            )}
            {isWithdrawalModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <p>Are you sure you want to withdraw SAPEON-LLM membership?</p>
                        <button onClick={handleWithdrawalModalYes}>Yes</button>
                        <button onClick={handleWithdrawalModalNo}>No</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Account;
