import React, { useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FaArrowDown } from 'react-icons/fa';
import './ChatWindow.css';
import ChatHeader from './ChatHeader';
import MessageBox from './MessageBox';
import InputBox from './InputBox';
import SampleQuestions from './SampleQuestions'; 
import ALL_SAMPLE_QUESTIONS from './SampleQuestionsList';
import { keycloak } from '../../keycloak';

const shuffleArray = (array, seed) => {
    let m = array.length, t, i;
    while (m) {
        i = Math.floor(seed * m);
        m -= 1;
        t = array[m];
        array[m] = array[i];
        array[i] = t;
    }
    return array;
};

const formatTimestamp = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const toReqFormatTimestamp = (formatTimestampStr) => {
    const date = new Date(formatTimestampStr);
    const pad = (num) => String(num).padStart(2, '0');
    return `${date.getFullYear()}${pad(date.getMonth() + 1)}${pad(date.getDate())}${pad(date.getHours())}${pad(date.getMinutes())}${pad(date.getSeconds())}`;
};

const toFormatTimestamp = (resFormatTimestamp) => {
    return resFormatTimestamp.replace("T", " ").replace("Z", "");
}

const ChatWindow = ({ apiConfig, setApiConfig }) => {
    const defaultRoomName = 'Default Room Name';
    const [chatRoomName, setChatRoomName] = useState(defaultRoomName);
    const defaultMessage = { id: uuidv4(), text: "Ask anything", type: "bot", profile: 'https://via.placeholder.com/40', timestamp: formatTimestamp(new Date()), onSpeech: false };
    const [messages, setMessages] = useState(Array(apiConfig.batch).fill([]));
    const [inputs, setInputs] = useState(Array(apiConfig.batch).fill(''));
    const [isAtBottom, setIsAtBottom] = useState(Array(apiConfig.batch).fill(true));

    
    const [offSampleQuestions, setOffSampleQuestions] = useState(Array(apiConfig.batch).fill(false));
    const [ready, setReady] = useState(false);
    const [streaming, setStreaming] = useState(false);

    const messagesEndRef = useRef([]);
    const messagesContainerRef = useRef([]);
    const botMessageRef = useRef([]);

    const generateRandomSeeds = (batchSize) => {
        return Array.from({ length: batchSize }, () => Math.random());
    };
    
    const [randomSeeds, setRandomSeeds] = useState(generateRandomSeeds(apiConfig.batch));

    useEffect(() => {
        const fetchLLMConfig = async () => {
            if (apiConfig.llmBackendURL === '') {
                try {
                    let response = await fetch(`https://sapeon-llm-api.artiference.sapeon.net/api/v1/llm`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + keycloak.token,
                        },
                    });

                    if (response.status === 404) {
                        response = await fetch(`https://sapeon-llm-api.artiference.sapeon.net/api/v1/llm`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + keycloak.token,
                            },
                            body: JSON.stringify({ model: apiConfig.model, batch: apiConfig.batch }),
                        });
                        if (!response.ok) throw new Error('Network response for Creating LLM was not ok');
                    } else if (!response.ok) {
                        throw new Error('Network response for Getting LLM was not ok');
                    }

                    const data = await response.json();
                    const updatedConfig = {
                        ...apiConfig,
                        model: data.model,
                        llm_id: data.id,
                        llmBackendURL: 'https://' + data.service_url,
                        batch: data.batch
                    };
                    setApiConfig(updatedConfig);
                } catch (error) {
                    console.error('There was a problem with the fetch operation:', error);
                }
            } else {
                setTimeout(async () => {
                    const healthCheckInterval = setInterval(async () => {
                        try {
                            const response = await fetch(`${apiConfig.llmBackendURL}/v1/chat/health`, {
                                method: 'GET',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                            });
                            if (!response.ok) throw new Error('Healthcheck request was not ok');
                            setReady(true);
                            clearInterval(healthCheckInterval);
                        } catch (error) {
                            setReady(false);
                            console.error('Error during healthcheck:', error);
                        }
                    }, 1000);
                }, 1000);
            }
        };

        fetchLLMConfig();
    }, [apiConfig.llmBackendURL, setApiConfig]);
    
    useEffect(() => {
        const fetchHistory = async () => {
            if (apiConfig.page_id !== '' && apiConfig.page_name !== '') {
                setChatRoomName(apiConfig.page_name);

                try {
                    const response = await fetch(`https://sapeon-llm-api.artiference.sapeon.net/api/v1/page/history/${apiConfig.page_id}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + keycloak.token,
                        },
                    });
                    const data = await response.json();

                    if (data && data.data) {
                        const newMessages = Array(apiConfig.batch).fill([]).map((_, index) => {
                            return data.data.reduce((messages, item, i) => {
                                if (i % apiConfig.batch === index) {
                                    if (i === 0) {
                                        messages.push({ id: uuidv4(), text: item.Answer, type: 'bot', profile: 'https://via.placeholder.com/40', timestamp: toFormatTimestamp(item.AnswerTimestamp) });
                                    } else {
                                        messages.push({ id: uuidv4(), text: item.Question, type: 'user', timestamp: toFormatTimestamp(item.QuestionTimestamp) });
                                        messages.push({ id: uuidv4(), text: item.Answer, type: 'bot', profile: 'https://via.placeholder.com/40', timestamp: toFormatTimestamp(item.AnswerTimestamp) });
                                    }
                                }
                                return messages;
                            }, []);
                        });
                        setMessages(newMessages);
                    }
                } catch (error) {
                    console.error('Error fetching history:', error);
                }
            } else {
                setChatRoomName(defaultRoomName);
                setMessages(Array(apiConfig.batch).fill([defaultMessage]));
                setIsAtBottom(Array(apiConfig.batch).fill(true));
                setRandomSeeds(generateRandomSeeds(apiConfig.batch));
                setOffSampleQuestions(Array(apiConfig.batch).fill(false));
            }
        };

        fetchHistory();
    }, [apiConfig]);
    
    const handleSend = async () => {
        // Close SampleQuestions
        setOffSampleQuestions(Array(apiConfig.batch).fill(true));
        
        if (inputs.every(input => input.trim() === '')) return;
    
        // Create new user messages for each chat window
        const newUserMessages = inputs.map((input, index) => ({
            id: uuidv4(),
            text: input,
            type: 'user',
            timestamp: formatTimestamp(new Date()),
            index: index,
        }));
    
        const payloadMessages = inputs.map(input => ({ role: 'user', content: input }));
    
        // Append user messages to the existing messages
        setMessages(prevMessages => prevMessages.map((messages, i) => {
            if (i < newUserMessages.length) {
                return [...messages, newUserMessages[i]];
            }
            return messages;
        }));
    
        // Clear inputs
        setInputs(Array(apiConfig.batch).fill(''));
    
        const payload = {
            model: apiConfig.model,
            messages: payloadMessages,
            stream: true,
        };
    
        try {
            const response = await fetch(`${apiConfig.llmBackendURL}/v1/chat/completions`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json; charset=utf-8' },
                body: JSON.stringify(payload),
            });
    
            const reader = response.body.getReader();
            let done = false;
            setStreaming(true);
    
            // Initialize bot messages for each chat window
            const initialBotMessages = Array.from({ length: apiConfig.batch }, (_, index) => ({
                id: uuidv4(),
                text: '',
                type: 'bot',
                profile: 'https://via.placeholder.com/40',
                timestamp: formatTimestamp(new Date()),
                index: index
            }));

            setMessages(prevMessages => prevMessages.map((messages, i) => {
                if (i < initialBotMessages.length) {
                    return [...messages, initialBotMessages[i]];
                }
                return messages;
            }));
    
            botMessageRef.current = initialBotMessages;
    
            while (!done) {
                const { value, done: doneReading } = await reader.read();
                if (doneReading) break;
    
                const chunks = new TextDecoder().decode(value);
                try {
                    const partialChunkedCount = (chunks.match(/}\]}/g) || []).length;
                    let lastIndex = 0;
    
                    for (let i = 0; i < partialChunkedCount; i++) {
                        const splitIndex = chunks.indexOf("}]}", lastIndex);
                        const tempJson = JSON.parse(chunks.substring(lastIndex, splitIndex + 3));
                        lastIndex = splitIndex + 3;
                        tempJson.choices.forEach((choice, index) => {
                            if (index < botMessageRef.current.length) {
                                const botIndex = index;
                                // Update the bot message content
                                setMessages(prevMessages => prevMessages.map((messages, i) => {
                                    if (i === botIndex) {
                                        return messages.map((message, ii) => {
                                            if (message.type === 'bot' && message.index === botIndex && ii === messages.length - 1) {
                                                return { ...message, text: message.text + choice.delta.content };
                                            }
                                            return message;
                                        });
                                    }
                                    return messages;
                                }));
    
                                if (choice.finish_reason === 'stop') {
                                    // Mark message as finished
                                    setMessages(prevMessages => prevMessages.map((messages, i) => {
                                        if (i === botIndex) {
                                            return messages.map(message => {
                                                if (message.type === 'bot' && message.index === botIndex) {
                                                    return { ...message, finished: true };
                                                }
                                                return message;
                                            });
                                        }
                                        return messages;
                                    }));
                                    setStreaming(false);
                                }
                            }
                        });
                    }
                } catch (error) {
                    console.error('Error parsing chunk:', error);
                }
            }
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    const handleStop = async () => {
        await fetch(`${apiConfig.llmBackendURL}/v1/chat/stop`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + keycloak.token,
            },
        });
        setStreaming(false);
    };

    // Scroll to bottom on messages update
    useEffect(() => {
        const timeout = setTimeout(() => {
            messages.forEach((_, index) => {
                if (isAtBottom[index] && messagesEndRef.current[index]) {
                    messagesEndRef.current[index].scrollIntoView({ behavior: 'smooth' });
                }
            });
        }, 100);
        return () => clearTimeout(timeout);
    }, [messages, isAtBottom]);

    const handleScroll = (index) => {
        const container = messagesContainerRef.current[index];
        if (container) {
            const { scrollTop, scrollHeight, clientHeight } = container;
            setIsAtBottom(isAtBottom.map((item, i) => (i === index ? scrollTop + clientHeight === scrollHeight : item)));
        }
    };

    const handleInputChange = (index, value) => {
        setInputs(prevInputs => {
            const newInputs = [...prevInputs];
            newInputs[index] = value;
            return newInputs;
        });
    };

    const handleSaveConfirm = async (chatRoomName) => {
        const pageBody = {
            model: apiConfig.model,
            name: chatRoomName,
            ...(apiConfig.page_id && { page_id: apiConfig.page_id }),
        };

        try {
            const response = await fetch(`https://sapeon-llm-api.artiference.sapeon.net/api/v1/page/${apiConfig.llm_id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + keycloak.token,
                },
                body: JSON.stringify(pageBody),
            });
            if (!response.ok) throw new Error('Network response was not ok for creating pages');
            const data = await response.json();
            if (data && data.data) {
                for (let i = 0; i < messages.length; i++) {
                    for (let j = 0; j < messages[i].length /2; j++) {
                        const historyBody = {
                            question: j === 0 ? messages[i][0].text : messages[i][j*2-1].text,
                            question_timestamp: toReqFormatTimestamp(j === 0 ? messages[i][0].timestamp : messages[i][j*2-1].timestamp),
                            answer: messages[i][j === 0 ? 0 : j*2].text,
                            answer_timestamp: toReqFormatTimestamp(messages[i][j === 0 ? 0 : j*2].timestamp),
                            latency: 1,
                        };

                        const historyResponse = await fetch(`https://sapeon-llm-api.artiference.sapeon.net/api/v1/page/history/${data.data.id}`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + keycloak.token,
                            },
                            body: JSON.stringify(historyBody),
                        });
                        if (!historyResponse.ok) throw new Error('Network response was not ok for creating history');
                    }
                }

                const updatedConfig = {
                    ...apiConfig,
                    page_id: data.data.id,
                    page_name: data.data.name,
                };
                setApiConfig(updatedConfig);
            }
        } catch (error) {
            console.error('Error saving chat:', error);
        }
    };

    const handleDeleteConfirm = async () => {
        if (!apiConfig.page_id) return;

        try {
            const response = await fetch(`https://sapeon-llm-api.artiference.sapeon.net/api/v1/page/${apiConfig.page_id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + keycloak.token,
                },
            });
            if (!response.ok) console.log("Chat history not exist");

            setApiConfig({ ...apiConfig, page_id: '' });
            setChatRoomName(defaultRoomName);
            setMessages(Array(apiConfig.batch).fill([defaultMessage]));
        } catch (error) {
            console.error('Error deleting chat:', error);
        }
    };

    const handleModelUpdate = async (selectedModel, selectedBatch) => {
        setApiConfig(prevConfig => ({ ...prevConfig, model: selectedModel, batch: selectedBatch }));

        try {
            await fetch(`${apiConfig.llmBackendURL}/v1/chat/close`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + keycloak.token,
                },
            });
            setReady(false);
            await fetch(`https://sapeon-llm-api.artiference.sapeon.net/api/v1/llm/${apiConfig.llm_id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + keycloak.token,
                },
                body: JSON.stringify({ model: selectedModel, batch: selectedBatch }),
            });
            setReady(false);
        } catch (error) {
            console.error('Error updating model:', error);
        }
        setApiConfig({ ...apiConfig, llmBackendURL: '' });
    };


    const getRandomQuestions = (questions, randomSeed) => {
        const shuffled = shuffleArray([...questions], randomSeed);
        return shuffled.slice(0, 4);
    };

    const handleSampleQuestionSelect = (index, question) => {
        handleInputChange(index, question);
        setOffSampleQuestions(offSampleQuestions.map((item, i) => (i === index ? true : item)));
    };
    
    const handleGenerate = () => {
        const newRandomSeeds = generateRandomSeeds(apiConfig.batch);
        setRandomSeeds(newRandomSeeds);
        const getRandomQuestion = (questions, randomSeed) => {
            const shuffled = shuffleArray([...questions], randomSeed);
            return shuffled[0];
        };
        const newInputs = newRandomSeeds.map(seed => getRandomQuestion(ALL_SAMPLE_QUESTIONS, seed));
        setInputs(newInputs);
        setOffSampleQuestions(Array(apiConfig.batch).fill(true));
    }

    return (
        <div className="chat-batch-container">
            <ChatHeader
                apiConfig={apiConfig}
                modelName={apiConfig.model}
                chatRoomName={chatRoomName}
                setChatRoomName={setChatRoomName}
                onSaveConfirm={handleSaveConfirm}
                onDeleteConfirm={handleDeleteConfirm}
                handleModelUpdate={handleModelUpdate}
                handleGenerate={handleGenerate}
                handleSend={handleSend}
                handleStop={handleStop}
                ready={ready}
                streaming={streaming}
            />
            <div className="chat-batch-windows-container">
                {messages.map((separtedMessage, index) => (
                    <div className="chat-window" key={index}>
                        <div className="messages" ref={el => messagesContainerRef.current[index] = el} onScroll={() => handleScroll(index)}>
                            {separtedMessage.map((message) => (
                                <MessageBox
                                    key={message.id}
                                    apiConfig={apiConfig}
                                    setMessages={(newMessages) => setMessages(prevMessages => prevMessages.map((msgs, i) => i === index ? newMessages : msgs))}
                                    messageId={message.id}
                                    text={message.text}
                                    type={message.type}
                                    profile={message.profile}
                                    timestamp={message.timestamp}
                                    onSpeech={message.onSpeech}
                                />
                            ))}
                            <div ref={el => messagesEndRef.current[index] = el} />
                        </div>
                        {offSampleQuestions[index]!==true&&(<SampleQuestions
                            questions={getRandomQuestions(ALL_SAMPLE_QUESTIONS, randomSeeds[index])}
                            onSelect={(question) => handleSampleQuestionSelect(index, question)}
                        />)}
                        <InputBox
                            apiConfig={apiConfig}
                            index={index}
                            input={inputs[index]}
                            onInputChange={handleInputChange}
                            handleSend={handleSend}
                            handleStop={handleStop}
                            ready={ready}
                            streaming={streaming}
                        />
                    </div>
                ))}
            </div>
        </div>
    );    
};

export default ChatWindow;
