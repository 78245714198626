import Keycloak from 'keycloak-js';

const keycloakURL = process.env.keycloakURL || 'https://artiference.sapeon.net/auth';

const keycloak = new Keycloak({
  url: keycloakURL,
  realm: 'artiference',
  clientId: 'sapeon-llm',
});

// Function to get an admin token
const getAdminToken = async () => {
  try {
    const response = await fetch(`${keycloakURL}/realms/master/protocol/openid-connect/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        grant_type: 'password',
        client_id: 'admin-cli',
        username: 'user',
        password: 'artiadmin',
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to get admin token');
    }

    const data = await response.json();
    return data.access_token;
  } catch (error) {
    console.error('Error getting admin token:', error);
  }
};

// Function to get roles from Keycloak
const getRoles = async () => {
  const adminToken = await getAdminToken();

  try {
    const response = await fetch(`${keycloakURL}/admin/realms/artiference/roles`, {
      headers: {
        'Authorization': `Bearer ${adminToken}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch roles');
    }

    const roles = await response.json();
    return roles;
  } catch (error) {
    console.error('Error fetching roles:', error);
  }
};

// Function to get Role ID by role name
const getRoleIdByName = async (roleName) => {
  const roles = await getRoles();
  if (!roles) return;
  const role = roles.find(r => r.name === roleName);
  return role ? role.id : null;
};

// Function to remove a role from a user
const removeRoleFromUser = async (userId, roleName) => {
  const adminToken = await getAdminToken();
  const roleId = await getRoleIdByName(roleName);
  if (!roleId) {
    console.error('Role not found');
    return;
  }
  try {
    const response = await fetch(`${keycloakURL}/admin/realms/artiference/users/${userId}/role-mappings/realm`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${adminToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify([{ id: roleId, name: roleName }]),
    });

    if (!response.ok) {
      throw new Error('Failed to remove role from user');
    }
  } catch (error) {
    console.error('Error removing role:', error);
  }
};

// Export the keycloak instance and functions
export { keycloak, removeRoleFromUser };
