import React, { useState } from 'react';
import { IoSettingsOutline } from "react-icons/io5";
import { FaEllipsisV } from 'react-icons/fa';
import './Plugin.css';

const Plugin = ({ apiConfig, setApiConfig, onButtonClick }) => {
    const [gender, setGender] = useState('male');
    const [audioSpeed, setAudioSpeed] = useState(1.0); 

    const handleGenderChange = (event) => {
        setGender(event.target.value);
        setApiConfig({ ...apiConfig, audioGender: event.target.value });
    };

    const handleAudioSpeedChange = (event) => {
        setAudioSpeed(parseFloat(event.target.value));
        setApiConfig({ ...apiConfig, audioSpeed: parseFloat(event.target.value) });
    };

    return (
        <div className='plugin-container'>
            <div className='plugin-title'>
                <IoSettingsOutline className='icon' size={25} />
                <div className='title-name'> Audio Settings</div>
            </div>
            <div className='gender-selection'>
                <label className="gender-label">
                    <input
                        type="radio"
                        id="male"
                        name="gender"
                        value="male"
                        checked={gender === 'male'}
                        onChange={handleGenderChange}
                    />
                    <span className="gender-text">Male</span>
                </label>

                <label className="gender-label">
                    <input
                        type="radio"
                        id="female"
                        name="gender"
                        value="female"
                        checked={gender === 'female'}
                        onChange={handleGenderChange}
                    />
                    <span className="gender-text">Female</span>
                </label>
            </div>
            <div className='speed-selection'>
                <span className="speed-text">Speed selection: {audioSpeed.toFixed(1)}</span>
                <input
                    type="range"
                    min="0.0"
                    max="2.0"
                    step="0.1"
                    value={audioSpeed}
                    onChange={handleAudioSpeedChange}
                    className="speed-slider"
                />
            </div>
        </div>
    );
};

export default Plugin;
