import React, { useEffect, useState } from 'react';
import { FaEllipsisV } from 'react-icons/fa';
import './ChatHeader.css';
import '../Modal.css';

function getBatchSize(batchType) {
    switch (batchType.toLowerCase()) {
        case 'general':
            return 1;
        case 'batch-llm':
            return 4;
        default:
            throw new Error(`Unknown batch type: ${batchType}`);
    }
}

function getBatchType(batchSize) {
    switch (batchSize) {
        case 1:
            return 'general';
        case 4:
            return 'batch-llm';
        default:
            throw new Error(`Unknown batch size: ${batchSize}`);
    }
}

const ChatHeader = ({ apiConfig, chatRoomName, setChatRoomName, modelName, onSaveConfirm, onDeleteConfirm, handleModelUpdate, handleGenerate, handleSend, handleStop, ready, streaming }) => {
    const [isModeDropdownOpen, setIsModeDropdownOpen] = useState(false);
    const [isModelDropdownOpen, setIsModelDropdownOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleModelButtonClick = () => {
        setIsModelDropdownOpen(!isModelDropdownOpen);
    };

    const handleModeButtonClick = () => {
        setIsModeDropdownOpen(!isModeDropdownOpen);
    };

    const handleInputChange = (e) => {
        setChatRoomName(e.target.value);
    };

    const handleBlur = () => {
        setIsEditing(false);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            setIsEditing(false);
        }
    };

    const handleSaveClick = () => {
        onSaveConfirm(chatRoomName);
        setIsDropdownOpen(false);
    };

    const handleDeleteClick = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const handleModalConfirm = () => {
        onDeleteConfirm();
        setIsModalOpen(false);
        setIsDropdownOpen(false);
    };

    const handleModelSelect = (model) => {
        if (model !== apiConfig.model) {
            handleModelUpdate(model, apiConfig.batch);
        }
        setIsModelDropdownOpen(false);
    };

    const handleModeSelect = (mode) => {
        if (mode !== getBatchType(apiConfig.batch)) {
            handleModelUpdate(apiConfig.model, getBatchSize(mode));
        }
        setIsModeDropdownOpen(false);
    };
    return (
        <div className="chat-header">
            <div className="chat-header-main">
            {apiConfig.membership === 'admin' && (
                <div className="dropdown">
                    {isModeDropdownOpen && (
                        <div className="model-dropdown">
                            {apiConfig.modeList.map((mode) => (
                                <div 
                                    key={mode} // Adding key for performance and to avoid React warnings
                                    className="chat-mode" 
                                    onClick={() => handleModeSelect(mode)} // Pass the model to the function
                                    style={{ backgroundColor: mode === getBatchType(apiConfig.batch) ? '#dc9b11' : '#555' }} // Highlight the selected model
                                >
                                    {mode}
                                </div>
                            ))}
                        </div>
                    )}
                    {!isModeDropdownOpen && (
                        <div className="chat-mode" onClick={handleModeButtonClick}>
                            {getBatchType(apiConfig.batch)}
                        </div>
                    )}
                </div>
            )}
            <div className="dropdown">
                {isModelDropdownOpen && (
                    <div className="model-dropdown">
                        {apiConfig.modelList.map((model) => (
                            <div 
                                key={model} // Adding key for performance and to avoid React warnings
                                className="chat-model" 
                                onClick={() => handleModelSelect(model)} // Pass the model to the function
                                style={{ backgroundColor: model === modelName ? '#9463ee' : '#555' }} // Highlight the selected model
                            >
                                {model}
                            </div>
                        ))}
                    </div>
                )}
                {!isModelDropdownOpen && (
                    <div className="chat-model" onClick={handleModelButtonClick}>
                        {modelName}
                    </div>
                )}
            </div>
            <div>
            {apiConfig.batch === 1 && (
            <div className="chat-roomname">
                {isEditing ? (
                    <input 
                        type="text" 
                        value={chatRoomName} 
                        onChange={handleInputChange} 
                        onBlur={handleBlur} 
                        onKeyDown={handleKeyDown} 
                        autoFocus 
                        className="chat-room-input"
                    />
                ) : (
                    <span onClick={() => setIsEditing(true)}>{chatRoomName}</span>
                )}
                <div className="dropdown">
                    <FaEllipsisV 
                        className="button" 
                        size={20} 
                        color="#FFF" 
                        onClick={toggleDropdown} 
                    />
                    {isDropdownOpen && (
                        <div className="dropdown-menu">
                            <button onClick={handleSaveClick}>Save</button>
                            <button onClick={handleDeleteClick}>Delete</button>
                        </div>
                    )}
                </div>
            </div>
            )}
            </div>
            </div>
            {apiConfig.batch !==1 && (
                <div className='batch-button'>
                    {!streaming && (
                        <button
                        className={`first-button ${ready ? '' : 'disabled'} ${streaming ? 'second-button' : ''}`}
                        onClick={handleGenerate}
                        >
                            {'Generate Messages'}
                        </button>
                    )}
                    <button
                    className={`first-button ${ready ? '' : 'disabled'} ${streaming ? 'second-button' : ''}`}
                    onClick={streaming ? handleStop : handleSend}
                    disabled={!ready && !streaming}
                    >
                        {streaming ? 'Stop' : 'Send'}
                    </button>
                </div>
            )}
            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <p>Are you sure you want to delete chat history?</p>
                        <button onClick={handleModalConfirm}>Yes</button>
                        <button onClick={handleModalClose}>No</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChatHeader;
