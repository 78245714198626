import React, { useState, useEffect } from 'react';
import './App.css';

import DefaultLayout from './components/DefaultLayout';
import { keycloak } from './keycloak';
import {getConfig} from './components/config';

function App() {
  const [apiConfig, setApiConfig] = useState(getConfig());
  const [authenticated, setAuthenticated] = useState(false);
  const [hasPOCrole, setHasPOCrole] = useState(false);

  const getModelList = (role) => {
    if (role === 'prime' || 'admin') {
        return [ 'llama-2', 'llama-3', 'opt-125m' ];
    } else {
        return [ 'llama-2' ];
    }
};

const getDefaultModel = (role) => {
    if (role === 'prime' || 'admin') {
        return 'llama-3';
    } else {
        return 'llama-2';
    }
};

  useEffect(() => {
    keycloak.init({ onLoad: 'login-required' }).then(auth => {
      const keycloakRoles = keycloak.tokenParsed.realm_access.roles;
      if (keycloakRoles.includes('sapeon-llm') || keycloakRoles.includes('sapeon-llm-prime') || keycloakRoles.includes('sapeon-llm-admin')) {
        setAuthenticated(auth);
        let role = '';
        if (keycloakRoles.includes('sapeon-llm-prime')) {
          role = 'prime';
        } else if (keycloakRoles.includes('sapeon-llm-admin')) {
          role = 'admin';
        } else {
          role = 'basic';
        }
        const updatedConfig = {
          ...apiConfig,
          membership: role,
          modelList: getModelList(role),
          model: getDefaultModel(role),
      };
      //updateConfig(updatedConfig);
      setApiConfig(updatedConfig);
      }else {
        alert("Please contact the administrator to get the access to SAPEON LLM");
        keycloak.logout();
      }
    }).catch(error => {
      console.error('Keycloak initialization error:', error);
    });
  }, []);

  if (!authenticated) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <div className="main-content">
        <DefaultLayout apiConfig={apiConfig} setApiConfig={setApiConfig}/>
      </div>
    </div>
  );
}

export default App;
