import React, { useState } from 'react';
import './Sidebar.css';
import Account from './Account';
import ChatList from './ChatList';
import Plugin from './Plugin';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'; // Add react-icons for fold/unfold icons

const Sidebar = ({ apiConfig, setApiConfig }) => {
    const [isFolded, setIsFolded] = useState(false); // State to track if the sidebar is folded

    const toggleSidebar = () => {
        setIsFolded(prevState => !prevState); // Toggle the state
    };

    return (
        <div className={`sidebar ${isFolded ? 'folded' : 'unfolded'}`}>
            <button className="toggle-btn" onClick={toggleSidebar}>
                {isFolded ? <FaArrowRight /> : <FaArrowLeft />} {/* Icons for fold/unfold */}
            </button>
            <div className={`container-account-layout ${isFolded ? 'hidden' : ''}`}>
                <Account apiConfig={apiConfig} setApiConfig={setApiConfig} />
            </div>
            <div className={`container-chatlist-layout ${isFolded ? 'hidden' : ''}`}>
                <ChatList apiConfig={apiConfig} setApiConfig={setApiConfig} />
            </div>
            <div className={`container-plugin-layout ${isFolded ? 'hidden' : ''}`}>
                <Plugin apiConfig={apiConfig} setApiConfig={setApiConfig} />
            </div>
        </div>
    );
};

export default Sidebar;
