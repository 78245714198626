import React, { useState, useEffect } from 'react';
import { BsArchive, BsPencil, BsTrash } from "react-icons/bs";
import './ChatList.css';
import { keycloak } from '../../keycloak';
import 'animate.css';

const ChatList = ({ apiConfig, setApiConfig }) => {
    const [chatItems, setChatItems] = useState([]);
    const [animatingItemId, setAnimatingItemId] = useState(null); // State to track the item being animated

    useEffect(() => {
        const fetchChatItems = async () => {
            try {
                const response = await fetch(`https://sapeon-llm-api.artiference.sapeon.net/api/v1/page`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + keycloak.token,
                    }
                });
                const data = await response.json();
                if (data && data.data) {
                    // Sort chat items by 'created_at' in descending order
                    const sortedItems = data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                    setChatItems(sortedItems);
                }
            } catch (error) {
                console.error('Error fetching chat items:', error);
            }
        };

        fetchChatItems();
    }, [apiConfig]);

    const handleEditIconClick = (item) => {
        const updatedItems = chatItems.map(chatItem => {
            if (chatItem.id === item.id) {
                return { ...chatItem, isEditing: true };
            }
            return { ...chatItem, isEditing: false };
        });
        setChatItems(updatedItems);
    };

    const handleChatItemClick = (item) => {
        setAnimatingItemId(item.id); // Set the item to animate
        setApiConfig({ ...apiConfig, page_id: item.id, page_name: item.name });
    };

    const handleEditInputChange = (event, item) => {
        const newName = event.target.value;
        const updatedItems = chatItems.map(chatItem => {
            if (chatItem.id === item.id) {
                return { ...chatItem, name: newName };
            }
            return chatItem;
        });
        setChatItems(updatedItems);
    };

    const handleEditInputBlur = async (item) => {
        await updateChatItemName(item.id, item.name);
        const updatedItems = chatItems.map(chatItem => {
            if (chatItem.id === item.id) {
                return { ...chatItem, isEditing: false };
            }
            return chatItem;
        });
        setChatItems(updatedItems);
    };

    const handleEditKeyDown = async (e, item) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            await handleEditInputBlur(item);
        }
    };

    const updateChatItemName = async (itemId, newName) => {
        try {
            const response = await fetch(`https://sapeon-llm-api.artiference.sapeon.net/api/v1/page/${itemId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + keycloak.token,
                },
                body: JSON.stringify({ name: newName }),
            });
            if (!response.ok) {
                throw new Error('Failed to update item name');
            }
        } catch (error) {
            console.error('Error updating chat item name:', error);
        }
    };

    const handleDeleteItemClick = async (itemId) => {
        try {
            const response = await fetch(`https://sapeon-llm-api.artiference.sapeon.net'/api/v1/page/${itemId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + keycloak.token,
                },
            });
            if (!response.ok) {
                throw new Error('Failed to delete item');
            }
            const updatedItems = chatItems.filter(item => item.id !== itemId);
            setChatItems(updatedItems);
            if (itemId === apiConfig.page_id) {
                setApiConfig({ ...apiConfig, page_id: '' });
            }
        } catch (error) {
            console.error('Error deleting chat item:', error);
        }
    };

    const groupChatItemsByDate = (items) => {
        const grouped = items.reduce((groups, item) => {
            const date = new Date(item.created_at).toLocaleDateString();
            if (!groups[date]) {
                groups[date] = [];
            }
            groups[date].push(item);
            return groups;
        }, {});

        return Object.keys(grouped).map(date => ({
            date,
            items: grouped[date].sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) // Sort items by created_at descending
        }));
    };

    const groupedChatItems = groupChatItemsByDate(chatItems);

    const onButtonClick = () => {
        setApiConfig({ ...apiConfig, page_id: '', page_name: '' });
    }

    return (
        <div className='chat-container'>
            <div className='title'>
                <BsArchive className='history-icon' size={25} />
                <div className='title-name'> Archive </div>
            </div>
            <div className='chat-list'>
                {groupedChatItems.map(group => (
                    <div key={group.date}>
                        <div className='chat-date'>{group.date}</div>
                        {group.items.map(item => (
                            <div
                                className={`chat-item ${animatingItemId === item.id ? 'animate__animated animate__fadeOutRight' : ''}`}
                                key={item.id}
                                onAnimationEnd={() => animatingItemId === item.id && setAnimatingItemId(null)} // Reset animation state
                            >
                                {item.isEditing ? (
                                    <input
                                        type="text"
                                        value={item.name}
                                        onChange={(event) => handleEditInputChange(event, item)}
                                        onBlur={() => handleEditInputBlur(item)}
                                        onKeyDown={(e) => handleEditKeyDown(e, item)}
                                        autoFocus
                                        className="chat-item-input"
                                    />
                                ) : (
                                    <>
                                        <div onClick={() => handleChatItemClick(item)}> - {item.name}</div>
                                        <div className='chat-item-icon'>
                                            <BsPencil className='edit-icon' onClick={() => handleEditIconClick(item)} />
                                            <BsTrash className='delete-icon' onClick={() => handleDeleteItemClick(item.id)} />
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div className='level'>
                <button className='chat-button' onClick={onButtonClick}>
                    + New Chat
                </button>
            </div>
        </div>
    );
};

export default ChatList;
