import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import './MessageBox.css';
import manSpeaker from './speaker/man_speaker.json';
import womanSpeaker from './speaker/woman_speaker.json';

function detectLanguage(text) {
    for (let i = 0; i < text.length; i++) {
        const charCode = text.charCodeAt(i);
        if ((charCode >= 0x1100 && charCode <= 0x11FF) || // 한글 자모음
            (charCode >= 0x3130 && charCode <= 0x318F) || // 한글 완성형
            (charCode >= 0xAC00 && charCode <= 0xD7AF)) { // 한글 음절
            return 'ko';
        } else if ((charCode >= 0x0041 && charCode <= 0x005A) || // 영어 대문자
                   (charCode >= 0x0061 && charCode <= 0x007A)) { // 영어 소문자
            return 'en';
        }
    }
    return 'unknown';
}

const MessageBox = ({ apiConfig, setMessages, messageId, text, type, profile, timestamp, onSpeech }) => {
    const [speaker, setSpeaker] = useState(null);
    const [speed, setSpeed] = useState(1.0);
    const [loading, setLoading] = useState(false); // Add loading state

    useEffect(() => {
        const fetchAudioSetting = async () => {
            if (apiConfig.audioGender === "male") {
                setSpeaker(manSpeaker);
            } else {
                setSpeaker(womanSpeaker);
            }
            setSpeed(apiConfig.audioSpeed);
        };
        fetchAudioSetting();
    }, [apiConfig]);

    const handleClick = async () => {
        if (!speaker) return; // Prevent from running if speaker is not loaded

        setLoading(true); // Set loading to true when starting to fetch

        try {
            const response = await fetch(`${apiConfig.xttsServerURL}/tts_stream`, {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    text: text,
                    language: detectLanguage(text),
                    speaker_embedding: speaker.speaker_embedding,
                    gpt_cond_latent: speaker.gpt_cond_latent,
                    add_wav_header: true,
                    stream_chunk_size: "100"
                })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const reader = response.body.getReader();

            const handleStream = async () => {
                try {
                    let audioBuffer = [];
            
                    while (true) {
                        const { done, value } = await reader.read();
                        if (done) {
                            break;
                        }
                        audioBuffer.push(value);
                    }

                    const audioBlob = new Blob(audioBuffer, { type: 'audio/wav' });
                    const audioUrl = URL.createObjectURL(audioBlob);
                    const audio = new Audio(audioUrl);
                    audio.playbackRate = speed; 
                    audio.play();
                    audio.addEventListener('ended', () => {
                        URL.revokeObjectURL(audioUrl);
                    });

                } catch (error) {
                    console.error('Error reading stream:', error);
                } finally {
                    reader.releaseLock();
                }
            };

            await handleStream();
        } catch (error) {
            console.error('Fetch error:', error);
        } finally {
            setLoading(false); // Set loading to false when finished
        }
    };

    useEffect(() => {
        if (onSpeech === true) {
            handleClick();
            setMessages(prevMessages => {
                return prevMessages.map(message => {
                    if (message.id === messageId) {
                        return { ...message, onSpeech: false };
                    }
                    return message;
                });
            });
        }
    }, [onSpeech, handleClick, messageId, setMessages]);

    return (
        <div className={`message-container ${type}`}>
            {type === 'bot' && (
                <img src={`${process.env.PUBLIC_URL}/Artiference.png`} alt="Profile" className="profile-image" />
            )}
            <div className={`message ${type}`} onClick={handleClick}>
                <div className="message-content">
                    {type === 'bot' ? (
                        <ReactMarkdown>{text}</ReactMarkdown>
                    ) : (
                        <div>{text}</div>
                    )}
                </div>
                <div className="message-timestamp">{timestamp}</div>
                {loading && <div className="spinner"></div>} {/* Show spinner while loading */}
            </div>
        </div>
    );
};

export default MessageBox;
