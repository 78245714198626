import React from 'react';
import './InputBox.css';

const InputBox = ({ apiConfig, index, input, onInputChange, handleSend, handleStop, ready, streaming }) => {
    return (
        <div className="input-area">
            <input
                type="text"
                placeholder="Type a message..."
                value={input}
                onChange={(e) => onInputChange(index, e.target.value)}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') streaming ? handleStop() : handleSend();
                }}
            />
            {apiConfig.batch===1 &&(<button
                className={`first-button ${ready ? '' : 'disabled'} ${streaming ? 'second-button' : ''}`}
                onClick={streaming ? handleStop : handleSend}
                disabled={!ready && !streaming}
            >
                {streaming ? 'Stop' : 'Send'}
            </button>)}
        </div>
    );
};

export default InputBox;
