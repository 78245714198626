import React from 'react';
import PropTypes from 'prop-types';
import './SampleQuestions.css';

const SampleQuestions = ({ questions, onSelect }) => {
    return (
        <div className="sample-questions">
            {questions.map((question, index) => (
                <button
                    key={index}
                    className="sample-question-btn"
                    onClick={() => onSelect(question)}
                >
                    {question}
                </button>
            ))}
        </div>
    );
};

SampleQuestions.propTypes = {
    questions: PropTypes.arrayOf(PropTypes.string).isRequired,
    onSelect: PropTypes.func.isRequired,
};

export default SampleQuestions;
