let config = {
    membership: '', 
    modelList: [],
    model: '', 
    modeList: ['general', 'batch-llm'],
    batch: 1, 
    llm_id: '', 
    page_id: '',
    page_name: '',
    llmBackendURL: '',
    lmAPIServerURL: 'https://sapeon-llm-api.artiference.sapeon.net',
    xttsServerURL: 'https://xtts-server.artiference.sapeon.net', // Use environment variable or default URL
    audioGender: 'male',
    audioSpeed: 1.0,

};

export const updateConfig = (newConfig) => {
    config = { ...config, ...newConfig };
};

export const getConfig = () => {
    return config;
};
