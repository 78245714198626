const ALL_SAMPLE_QUESTIONS = [
    "What's the weather like today?",
    "Can you tell me a joke?",
    "How do I make a cup of coffee?",
    "What's the best way to learn React?",
    "What's the capital of France?",
    "How do I cook spaghetti?",
    "Can you recommend a good book?",
    "How do I start a garden?",
    "What's the latest news in technology?",
    "How can I improve my public speaking skills?",
    "What are some tips for healthy eating?",
    "How do I manage my time effectively?",
    "What's the best way to learn a new language?",
    "How can I become a better writer?",
    "What's your favorite movie?",
    "Can you tell me a fun fact?",
    "How do I meditate?",
    "What are the benefits of exercise?",
    "How do I improve my memory?",
    "What is the meaning of life?",
    "How do I build a website?",
    "What's the best way to save money?",
    "How do I deal with stress?",
    "What's the most popular travel destination?",
    "How do I start a blog?",
    "What are some good interview tips?",
    "How do I make friends?",
    "What's the best way to learn math?",
    "How do I become more confident?",
    "What's the best way to learn to code?",
    "How can I improve my diet?",
    "What's the difference between a virus and bacteria?",
    "How do I take better photos with my smartphone?",
    "What's the best way to manage my finances?",
    "How can I improve my communication skills?",
    "What are some effective study techniques?",
    "How do I start a fitness routine?",
    "What's the best way to organize my workspace?",
    "How do I handle conflicts in the workplace?",
    "What are some good habits for success?",
    "How do I create a budget?",
    "What are some popular hobbies to try?",
    "How do I write a compelling resume?",
    "What's the best way to network professionally?",
    "How do I balance work and personal life?",
    "What are some tips for effective teamwork?",
    "How do I set and achieve personal goals?",
    "What's the best way to learn a musical instrument?",
    "How do I stay motivated during a long project?",
    "What are some fun activities to do on a weekend?",
    "How do I improve my critical thinking skills?",
    "What are some strategies for effective time management?",
    "How do I prepare for a job interview?",
    "What's the best way to learn a new skill?",
    "How do I deal with difficult people?",
    "What are some ways to stay healthy while traveling?",
    "How do I write a successful business plan?",
    "What's the best way to overcome procrastination?",
    "How do I create a personal brand?",
    "What are some effective ways to relieve stress?",
    "How do I develop a growth mindset?",
    "What's the best way to handle criticism?",
    "How do I improve my problem-solving skills?",
    "What are some good practices for remote work?",
    "How do I build and maintain strong relationships?",
    "What's the best way to save for retirement?",
    "How do I improve my public speaking abilities?",
    "What are some tips for managing anxiety?",
    "How do I set realistic and achievable goals?",
    "What's the best way to learn about investing?",
    "How do I stay productive when working from home?",
    "What are some tips for effective leadership?",
    "How do I find my passion and purpose in life?",
    "What's the best way to deal with failure?",
    "How do I improve my negotiation skills?",
    "What are some ways to enhance creativity?",
    "How do I manage and reduce my debt?",
    "What's the best way to learn about different cultures?",
    "How do I create a positive work environment?",
    "What are some tips for successful entrepreneurship?",
    "How do I maintain a healthy work-life balance?",
    "What's the best way to handle financial setbacks?",
    "How do I improve my listening skills?",
    "What are some strategies for effective goal setting?",
    "How do I get started with mindfulness and relaxation?",
    "What's the best way to stay informed about current events?",
    "How do I build resilience in challenging times?",
    "What are some techniques for improving focus and concentration?",
    "How do I handle and resolve conflicts effectively?",
    "What's the best way to manage a team?",
    "How do I improve my decision-making skills?",
    "What are some good strategies for personal growth?",
    "How do I create a healthy and fulfilling lifestyle?",
    "What's the best way to learn a new language quickly?",
    "How do I stay organized and manage tasks efficiently?",
    "What are some tips for maintaining a positive attitude?",
    "How do I find and cultivate a supportive network of people?",
    "What's the best way to set and maintain healthy boundaries?",
    "How do I improve my ability to work under pressure?",
    "What are some effective strategies for managing stress at work?",
    "How do I make time for self-care and relaxation?",
    "What's the best way to handle financial planning for a family?",
    "How do I develop effective problem-solving techniques?",
    "What are some ways to stay motivated and inspired?",
    "How do I build a successful career path?",
    "What's the best way to approach and solve complex problems?",
    "How do I manage my time effectively for long-term projects?",
    "What are some strategies for effective personal development?",
    "How do I stay healthy and fit during busy periods?",
    "What's the best way to learn about personal finance?",
    "How do I improve my adaptability and flexibility?",
    "What are some tips for achieving work-life harmony?",
    "How do I develop a positive and productive mindset?",
    "What's the best way to approach and solve personal challenges?"
];


export default ALL_SAMPLE_QUESTIONS;